import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className="mx-3 my-3">
      <h1>El enlace no existe.</h1>
      <hr />
      <Link to="/">Para volver al inicio haga click aquí.</Link>
    </div>
  )
}

export default NotFound