import React from 'react'
import { MDBCol, MDBRow } from 'mdbreact'
import CursoCard from '../components/CursoCard'
import Axios from 'axios'

const replaceURL = (url) => {
  if (process.env.NODE_ENV === 'production' && url)
    return url.replace('http://masterchile.cl/', '/')
  return url
}

class Cursos extends React.Component  {
  state = {
    cursos: []
  }

  componentDidMount () {
    Axios.get(`${process.env.REACT_APP_ENDPOINT}cursos&per_page=99`)
      .then(res => {
        let cursos = res.data.map(curso => {
          let acf = curso.acf
          return {
            id: curso.id,
            title: acf.title.substring(0, 50)+(acf.title.length > 50 ? '...':''),
            desc: acf.description.replace(/<[^>]*>/g, '').substring(0, 30)+'...',
            image: replaceURL(acf.imagen)
          }
        })
        this.setState({ cursos })
      })
      .catch( err => {
        console.log(err)
      })
  }

  render () {
    
    let cursosResp = this.state.cursos.map(curso => {
      return <MDBCol key={curso.id}><CursoCard id={curso.id} img={curso.image} title={curso.title} desc={curso.desc} /></MDBCol>
    })

    return(
      <div className="mx-3 my-3">
        <h1>Cursos disponibles</h1>
        <hr />
        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <MDBRow>
          {cursosResp}
        </MDBRow>
        </div>
      </div>
    )
  }
}
export default Cursos