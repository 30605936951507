import React from 'react'
import RoundedImage from 'react-rounded-image'
import { MDBIcon } from 'mdbreact'

class Testimonio extends React.Component {
  render () {
    const {name, img, desc} = this.props
    return(
      <div className="testimonio-box">
        <RoundedImage image={img} roundedColor="#FFFFFF" imageWidth="150" imageHeight="150"  />
          <div>
            <h3><MDBIcon style={{color: '#BBBBBB'}} icon="quote-left" /> <strong>{desc}</strong> <MDBIcon style={{color: '#BBBBBB'}} icon="quote-right" /></h3>
          </div>
          <div style={{color: '#CCCCCC'}}><h3><i>{name}</i></h3></div>
      </div>
    )
  }
}

export default Testimonio
