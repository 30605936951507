import React from 'react'
import { MDBContainer, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBCarouselCaption, MDBCol, MDBRow, MDBBtn, MDBIcon } from 'mdbreact'
// import logo from '../logo.png'
import logo from '../logo/logo-alt.png'
import CursoCard from '../components/CursoCard'
import ImageCard from '../components/ImageCard'
import history from '../router'
import * as ROUTES from '../router/routes'
// import RoundedImage from 'react-rounded-image'
// import noImage from '../logo/logo-noimage.png'
import axios from 'axios'
import SpinnerPage from '../components/SpinnerPage'
import {NoticiaSlider} from '../components/Noticia'
import Testimonio from '../components/Testimonio'

const replaceURL = (url) => {
  if (process.env.NODE_ENV === 'production' && url)
    return url.replace('http://masterchile.cl/', '/')
  return url
}

class Home extends React.Component {
  state = {
    slides: [],
    cursos: [],
    photos: [],
    profesionales: [],
    testimonios: [],
    homeText: 'Centro de capacitaciones en la región de la Araucanía',
    show: false
  }

  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}globals`)
      .then(res => {
        this.setState({ homeText: res.data[0].acf.home_text})

        return axios.get(`${process.env.REACT_APP_ENDPOINT}slides`)
      })
      .then(res => {
        let slides = res.data.map(slide => {
          return replaceURL(slide.acf.imagen)
        })
        this.setState({slides})
        return axios.get(`${process.env.REACT_APP_ENDPOINT}cursos`)
      })
      .then(res => {
        let cursos = res.data.map(curso => {
          let acf = curso.acf
          return {
            id: curso.id,
            title: acf.title.substring(0, 50)+(acf.title.length > 50 ? '...':''),
            desc: acf.description.replace(/<[^>]*>/g, '').substring(0, 30)+'...',
            image: replaceURL(acf.imagen)
          }
        })
        cursos = cursos.slice(0, 2)
        this.setState({ cursos })
        return axios.get(`${process.env.REACT_APP_ENDPOINT}photos`)
      })    
      .then(res => {
        let photos = res.data.map(photo => {
          const data = photo.acf
          // arreglo temporal
          let correctUrl = replaceURL(data.imagen)

          return {
            id: photo.id,
            desc: data.description,
            url: correctUrl// url: data.imagen
          }
        })
        photos = photos.slice(0, 4)

        this.setState({ photos })
        return axios.get(`${process.env.REACT_APP_ENDPOINT}testimonios`)
      })
      .then(res => {
        let testimonios = res.data.map(t => {
          return <MDBCol key={t.id}><Testimonio  name={t.acf.nombre} desc={t.acf.testimonio} img={t.acf.avatar} /></MDBCol>
        })
        this.setState({ testimonios })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {this.setState({show: true})})
    // axios.get(`${process.env.REACT_APP_ENDPOINT}profesionales`)
    //   .then(res => {
    //     let profesionales = res.data.map(prof => {
    //       return {
    //         image: replaceURL(prof.acf.avatar),
    //         name: prof.acf.name,
    //         desc: prof.acf.description
    //       }
    //     })

    //     this.setState({ profesionales })
    //   })
    //   .catch(err => { console.log(err) })
  }

  render() {
    const slidesGroup = this.state.slides.map((slide, index) => {
      return(
        <MDBCarouselItem key={index} itemId={index+1}>
          <MDBView>
            <img 
              className="d-block w-100"
              src={slide}
              alt={`Slide${index}`}
            />
          </MDBView>
        </MDBCarouselItem>
      )
    })

    let imgsObj = this.state.photos.map((img, index) => {
      if (!img) return <MDBCol key={index}><ImageCard /></MDBCol>
      return <MDBCol key={index}><ImageCard img={img.url}/></MDBCol>
    })

    let cursosResp = this.state.cursos.map((curso, index) => {
      return <MDBCol key={index}><CursoCard id={curso.id} img={curso.image} title={curso.title} desc={curso.desc} /></MDBCol>
    })

    // let profResp = this.state.profesionales.map((p, index) => {
    //   return <MDBCol key={index}><Avatar name={p.name} desc={p.desc} img={p.image} /></MDBCol> 
    // })
    if (!this.state.show) return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'flex-end', marginTop: '3rem'}}><SpinnerPage /> <strong> Corporación MasterChile Capacitación</strong></div>
    return(
      <div>
        <div className="carousel-titles">
          <div className="home-title">
            <img src={logo} alt="titlelogo" className="logo-title" />
            </div>
          {/* <hr style={{borderColor: '#ffffff'}} /> */}
          <hr />
        <div className="home-subtitle">{this.state.homeText}</div>
        </div>
        <MDBContainer fluid>
            <MDBCarousel
            activeItem={1}
            length={3}
            showControls={false}
            showIndicators={true}
            className='z-depth-1'
          >
            <MDBCarouselCaption>

            </MDBCarouselCaption>
            <MDBCarouselInner>
              {slidesGroup}
            </MDBCarouselInner>
          </MDBCarousel>          
        </MDBContainer>
        <div className="mx-2 my-5">
          <div className="home-noticias">
            <h1>Novedades</h1>
            <hr style={{maxWidth: '20rem'}} />
            <NoticiaSlider />
          </div>
          <div style={{textAlign: 'center'}} className="mt-5">       
            <h2 style={{textAlign: 'center'}}><strong>Participa de nuestros cursos</strong></h2>
            <hr style={{maxWidth: '20rem'}} />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <MDBRow>
                {cursosResp}
              </MDBRow>
              {/* <CursoCard img={demo1} title={'Curso A'} desc={'ESTA ES UNA DESCRIPCIÓN DEL CURSO...'} />
              <CursoCard img={demo2} title={'Curso B'} desc={'ESTA ES UNA DESCRIPCIÓN DEL CURSO...'} /> */}
            </div>
            <div className="mt-4" style={{display: 'flex', justifyContent: 'center'}}>
              <MDBBtn color={process.env.REACT_APP_COLOR} onClick={() => history.push(ROUTES.CURSOS)}><strong><MDBIcon icon="chalkboard-teacher" /> Ver cursos</strong></MDBBtn>
            </div>
          </div>
          <div style={{textAlign: 'center'}} className="mt-5 mb-3">
            <h2 style={{textAlign: 'center'}}><strong>Galería de Imágenes</strong></h2>
            <hr style={{maxWidth: '20rem'}} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
            <MDBRow>
              {imgsObj}
            </MDBRow>
            </div>
            <div className="mt-4" style={{display: 'flex', justifyContent: 'center'}}>
              <MDBBtn color={process.env.REACT_APP_COLOR} onClick={() => history.push(ROUTES.GALLERY)}><strong><MDBIcon icon="images" /> Ver galería</strong></MDBBtn>
            </div>
          </div>
          <div style={{textAlign: 'center'}} className="mt-5 mb-5">
              <h2>Testimonios</h2>
              <hr style={{maxWidth: '20rem'}} />
              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
              <MDBRow>
                {this.state.testimonios}
              </MDBRow>
              </div>
          </div>
          {/* <div style={{textAlign: 'center'}} className="mt-5 mb-5">
            <h2>Nuestros profesionales</h2>
            <hr style={{width: '50%'}} />
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Avatar name="Profesional A" desc="Título profesional, ocupacion." />
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <MDBRow>
                {profResp}
              </MDBRow>
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}

// const avatarStyles = {
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   marginLeft: '2rem',
//   maxWidth: '12rem'
// }

// class Avatar extends React.Component {
//   render () {
//     const {name, desc, img} = this.props
//     return(
//       <div style={avatarStyles}>
//         <RoundedImage image={img ? img : noImage} roundedColor="#FFFFFF" imageWidth="150" imageHeight="150"  />
//         <h4><strong>{name}</strong></h4>
//         <h6>{desc}</h6>
//       </div>
//     )
//   }
// }


export default Home
