import React from 'react'

import { MDBRow, MDBCol } from 'mdbreact'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'

const replaceURL = (url) => {
  if (process.env.NODE_ENV === 'production' && url)
    return url.replace('http://masterchile.cl/', '/')
  return url
}

class CursoDetail extends React.Component {
  state = {
    curso: {}
  }
  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}cursos/${this.props.id}`)
      .then(res => {
        let data = res.data.acf
        let curso = {
          id: res.data.id,
          title: data.title,
          desc: ReactHtmlParser('<div>'+data.description+'</div>'),
          image: replaceURL(data.imagen)
        }
        this.setState({ curso })
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    let curso = this.state.curso

    return(
      <div className="mx-3 my-3">
        <h1>{curso.title}</h1>
        <hr />
        <MDBRow className="ml-2 mr-2">
        {curso.image && <MDBCol xl="6" lg="6" md="6" sm="12" className="course-box" style={{backgroundImage: `url(${curso.image})`}} alt="" />}
          <MDBCol xl="6" lg="6" md="6" sm="12">
            {curso.desc}
          </MDBCol>
        </MDBRow>
      </div>
    )
  }
}

export default CursoDetail