import React from 'react'
import { MDBRow, MDBCol, MDBModal, MDBModalHeader, MDBModalBody, MDBTable, MDBTableBody } from 'mdbreact'
import axios from 'axios'
import noImage from '../logo/logo-noimage.png'
import RoundedImage from 'react-rounded-image'

const replaceURL = (url) => {
  if (process.env.NODE_ENV === 'production' && url)
    return url.replace('http://masterchile.cl/', '/')
  return url
}

class Equipo extends React.Component {
  state = {
    profesionales: [],
    title: 'Equipo',
    boss: {}
  }

  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}globals`)
      .then(res => {
        let title = res.data[0].acf.profesionales_titulo
        let boss = {
          image: res.data[0].acf.imagen_administrador,
          name: res.data[0].acf.nombre_administrador,
          desc: res.data[0].acf.descripcion_admin
        }
        this.setState({ title, boss })
      })
      .catch(err => console.log(err))
    axios.get(`${process.env.REACT_APP_ENDPOINT}profesionales`)
      .then(res => {
        let profesionales = res.data.map(prof => {
          return {
            image: replaceURL(prof.acf.avatar),
            name: prof.acf.name,
            desc: prof.acf.description,
            email: prof.acf.email,
            phone: prof.acf.telefono
          }
        })

        this.setState({ profesionales })
      })
      .catch(err => { console.log(err) })
  }
  
  render () {
    let profResp = this.state.profesionales.map((p, index) => {
      return <MDBCol key={index}><Avatar name={p.name} desc={p.desc} img={p.image} phone={p.phone} email={p.email} /></MDBCol> 
    })
    return(
      <div style={{textAlign: 'center'}} className="mt-5 mb-5">
        <h2>{this.state.title}</h2>
        <hr style={{width: '50%'}} />
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <Avatar name={this.state.boss.name} desc={this.state.boss.desc} img={this.state.boss.image} />
        </div>
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <MDBRow>
            {profResp}
          </MDBRow>
        </div>
      </div>
    )
  }
}

const avatarStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginLeft: '2rem',
  maxWidth: '12rem',
  cursor: 'pointer'
}


class Avatar extends React.Component {
  state = {
    modal: false
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal})
  }
  render () {
    const {name, desc, img, phone, email} = this.props
    return(
      <div onClick={this.toggle} style={avatarStyles}>
        <RoundedImage image={img ? img : noImage} roundedColor="#FFFFFF" imageWidth="150" imageHeight="150"  />
        <h4><strong>{name}</strong></h4>
        <h6>{desc}</h6>
        <MDBModal isOpen={this.state.modal} toggle={() => {}}>
        <MDBModalHeader toggle={this.toggle}>{name}</MDBModalHeader>
        <MDBModalBody>
          <img src={img ? img : noImage} alt="" width="300px" />
          <MDBTable borderless className="mt-2" striped>
            <MDBTableBody>
              <tr>
                <td>Ocupación</td><td>{desc}</td>
              </tr>
              <tr>
                <td>Teléfono</td><td>{phone}</td>
              </tr>
              <tr>
                <td>email</td><td>{email}</td>
              </tr>
            </MDBTableBody>
          </MDBTable>
        </MDBModalBody>
      </MDBModal>
      </div>
    )
  }
}

export default Equipo