import React from 'react'
import axios from 'axios'
import CursoCard from '../components/CursoCard'
import { MDBCol, MDBRow } from 'mdbreact'

const replaceURL = (url) => {
  if (process.env.NODE_ENV === 'production' && url)
    return url.replace('http://masterchile.cl/', '/')
  return url
}

class CursoByTag extends React.Component {
  state = {
    cursos: [],
    name: 'Cursos disponibles'
  }
  componentDidMount () {
    let area = this.props.id
    axios.get(`${process.env.REACT_APP_ENDPOINT}areas`)
      .then(res => {
        res.data.forEach(a => {
          if (area === `${a.id}`) {
            this.setState({ name: a.name })
          }
        })
      })
      .catch(err => { console.log(err) })
    axios.get(`${process.env.REACT_APP_ENDPOINT}cursos&per_page=99`)
      .then(res => {
        let cursos = []
        res.data.forEach(curso => {
          let isInArea = false
          curso.areas.forEach(a => {
            if (`${a}` === area) {
              isInArea = true
            }
          })
          if (isInArea) {
            cursos.push({
              id: curso.id,
              title: curso.acf.title.substring(0, 50)+(curso.acf.title.length > 50 ? '...':''),
              desc: curso.acf.description.replace(/<[^>]*>/g, '').substring(0, 30)+'...',
              image: replaceURL(curso.acf.imagen)
            })
          }
        })
        this.setState({ cursos })
      })
      .catch(err => { console.log(err) })
  }

  render () {
    
    let cursosResp = this.state.cursos.map(curso => {
      return <MDBCol key={curso.id}><CursoCard id={curso.id} img={curso.image} title={curso.title} desc={curso.desc} /></MDBCol>
    })

    return(
      <div className="mx-3 my-3">
        <h1>{this.state.name}</h1>
        <hr />
        <div style={{display: 'flex', justifyContent: 'flex-start'}}>
        <MDBRow>
          {cursosResp}
        </MDBRow>
        </div>
      </div>
    )
  }
}

export default CursoByTag
