import React from 'react'
import { MDBCard, MDBModal, MDBModalBody } from 'mdbreact'
import noImage from '../logo/logo-noimage.png'

class ImageCard extends React.Component {
  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    })
  }

  render() {
    const { img, desc } = this.props
    const outImg = img
      ? img
      : noImage
    return(
      <div>
      <MDBCard onClick={this.toggle} className="mt-3" style={{width: '18rem', cursor: 'pointer'}}>
        <div className="bounding-box" 
          style={{
            backgroundImage: `url(${outImg})`, 
            height: '13rem',
            width: 'auto',
            margin: '0.75rem'
            }}>
        </div>
        
        {/* <MDBCardImage className="img-fluid" src={outImg} waves /> */}
      </MDBCard>
      <MDBModal size='xl' isOpen={this.state.modal} toggle={this.toggle}>
        {/* <MDBModalHeader toggle={this.toggle}>MDBModal title</MDBModalHeader> */}
        <MDBModalBody style={{margin: 'auto'}}>
          <img src={outImg} alt='noimage' width="100%" />
          <p style={{marginTop: '2rem'}}>
            {desc}
          </p>
        </MDBModalBody>
        {/* <MDBModalFooter>
          <MDBBtn color="secondary" onClick={this.toggle}>Close</MDBBtn>
          <MDBBtn color="primary">Save changes</MDBBtn>
        </MDBModalFooter> */}
      </MDBModal>
      </div>
    )
  }
}

export default ImageCard