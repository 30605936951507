import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import ImageCard from '../components/ImageCard'
import axios from 'axios'

const replaceURL = (url) => {
  if (process.env.NODE_ENV === 'production' && url)
    return url.replace('http://masterchile.cl/', '/')
  return url
}

class Galeria extends React.Component {
  state = {
    photos: []
  }
  componentDidMount ()  {
    axios.get(`${process.env.REACT_APP_ENDPOINT}photos`)
      .then(res => {
        let photos = res.data.map(photo => {
          const data = photo.acf
          // arreglo temporal
          let correctUrl = replaceURL(data.imagen)

          return {
            id: photo.id,
            desc: data.description,
            url: correctUrl// url: data.imagen
          }
        })

        this.setState({ photos })
      })
  }
  render () {
    let photorender = this.state.photos.map(photo => {
      if (!photo.url) return <MDBCol key={photo.id}><ImageCard /></MDBCol>
      return <MDBCol key={photo.id}><ImageCard img={photo.url} desc={photo.desc} /></MDBCol>
    })

    return(
      <div className="mx-3 my-3">
        <h1>Galería de imágenes</h1>
        <hr />
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <MDBRow>
            {photorender}
          </MDBRow>
        </div>
      </div>
    )
  }
}

export default Galeria