import React from 'react'
import axios from 'axios'
import ReactHtmlParser from 'react-html-parser'
import { MDBRow, MDBCol } from 'mdbreact'

class About extends React.Component {
  state = {
    content: '',
    image: ''
  }
  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}globals`)
      .then(res => {
        let content = ReactHtmlParser('<div>'+res.data[0].acf.quienes_somos_texto+'</div>')
        let image = res.data[0].acf.quienes_somos_imagen

        this.setState({ content, image})
      })
      .catch(err => {console.log(err)})
  }
  render () {
    return(
      <div className="mx-3 mt-3 mb-3">
        <h1>Quienes somos</h1>
        <hr />
        <MDBRow>
          <MDBCol xl="6" lg="6" md="6">
          {this.state.content}
          </MDBCol>
          <MDBCol xl="6" lg="6" md="6"> 
          <img src={this.state.image} alt="" style={{width: '100%', height: 'auto'}} />
          </MDBCol>
        </MDBRow>        
      </div>
    )
  }
}

export default About
