import React from 'react';
import history from './router'
import * as ROUTES from './router/routes'
import { Router, Switch, Route, useParams } from 'react-router-dom'
import Home from './views/Home'
import About from './views/About'
import NotFound from './views/NotFound'
import Navbar from './components/Navbar'
import Galeria from './views/Galeria'
import Cursos from './views/Cursos'

import './App.css';
import Footer from './components/Footer';
import CursoDetail from './views/CursoDetail';
import Equipo from './views/Equipo';
import Noticia from './views/Noticia';
import CursoByTag from './views/CursoByTag';

class App extends React.Component {

  render () {
    return (
      <Router history={history}>
        <div className="page-wrap">
        <Navbar />
          <Switch>
            <Route exact path={ROUTES.HOME} component={Home} />
            <Route path={ROUTES.ABOUT} component={About} />
            <Route path={ROUTES.TEAM} component={Equipo} />
            <Route path={ROUTES.CURSOS} component={Cursos} />
            <Route path={ROUTES.GALLERY} component={Galeria} />
            <Route path={ROUTES.CURSO_DETALLE+'/:id'} component={CursoParams } />
            <Route path={ROUTES.CURSO_TAG+'/:id'} component={CursoByTagParams} />
            <Route path={ROUTES.NOTICIA+'/:id'} component={NoticiaParams} />
            <Route path={ROUTES.NOT_FOUND} component={NotFound} />
          </Switch>
        </div>
        <Footer />
      </Router>
    )
  }
}

const CursoParams = () => {
  let {id} = useParams()

  return(
    <div>
      <CursoDetail id={id} />
    </div>
  )
}

const CursoByTagParams = () => {
  let {id} = useParams()
  return (
    <div>
      <CursoByTag id={id} />
    </div>
  )
}

const NoticiaParams = () => {
  let {id} = useParams()
  return (
    <div>
      <Noticia id={id} />
    </div>
  )
}

export default App;
