import React from 'react'
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBIcon, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBBtn, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from 'mdbreact'
// import { Link } from 'react-router-dom'
import * as ROUTES from '../router/routes'
import history from '../router'
// import logo from '../logo.png'
import logo from '../logo/logo-binario.png'
import axios from 'axios'

const color = "amber"

class Navbar extends React.Component {
  state = {
    isOpen: false,
    areas: []
  }

  goTo(site) {
    history.push(site)
    window.location.reload()
  }

  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}areas`)
      .then(res => {
        let areas = res.data.map(area => {
          return <MDBDropdownItem key={area.id} onClick={() => { this.goTo(`/tag/${area.id}`)}}>{area.name}</MDBDropdownItem>
        })
        this.setState({ areas })
      })
      .catch(err => {console.log(err)})
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen})
  }

  render () {
    return (
        <MDBNavbar color="elegant-color" dark expand="lg">
          <MDBNavbarBrand style={{cursor: 'pointer'}} onClick={() => history.push(ROUTES.HOME)}>
            <img src={logo} alt="MasterchileLogo" style={{maxWidth: '18rem'}} /> 
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav right>
              <MDBNavItem>
                <MDBBtn className="nav-button" color={color} onClick={() => history.push(ROUTES.HOME)}><MDBIcon icon="home" /> <strong>Inicio</strong></MDBBtn>
              </MDBNavItem>
              <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle  nav>
                  <MDBBtn className="nav-button" color={color} ><strong><MDBIcon icon="info-circle" /> Nosotros</strong></MDBBtn>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  <MDBDropdownItem onClick={() => {history.push('/about')}}>Quienes somos</MDBDropdownItem>
                  <MDBDropdownItem onClick={() => {history.push('/team')}}>Equipo</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
              <MDBDropdown>
                <MDBDropdownToggle  nav>
                  <MDBBtn className="nav-button" color={color} ><strong><MDBIcon icon="chalkboard-teacher" /> Cursos</strong></MDBBtn>
                </MDBDropdownToggle>
                <MDBDropdownMenu>
                  {this.state.areas}
                </MDBDropdownMenu>
              </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <MDBBtn className="nav-button" color={color} onClick={() => history.push(ROUTES.GALLERY)}><strong><MDBIcon icon="images" /> Galería</strong></MDBBtn>
              </MDBNavItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
    )
  }
}
export default Navbar
