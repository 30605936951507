import React from 'react'
import axios from 'axios'
import noImage from '../logo/logo-noimage.png'
import TeParseo from 'react-html-parser'

const formattedDate = (date) => {
  return `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}`
}

class Noticia extends React.Component {
  state = {
    noticia: {}
  }
  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}noticias/${this.props.id}`)
      .then(res => {
        let noticia = {
          title: res.data.acf.titulo,
          desc: TeParseo('<div>'+res.data.acf.desc+'</div>'),
          image: res.data.acf.portada ? res.data.acf.portada: noImage,
          fecha: formattedDate(res.data.date)

        }
        this.setState({ noticia })
        // let noticia = {
        //   title: res.data.
        // }
      })
  }
  render () {
    return (
      <div style={{maxWidth: '900px', margin: '2rem'}}>
        <h1>{this.state.noticia.title}</h1>
        <hr />
        <div
            style={{
              width: '100%',
              height: '30rem',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundImage: `url(${this.state.noticia.image})`
              }}></div>
        <p style={{color: '#CCCCCC'}}><i>{this.state.noticia.fecha}</i></p>
        <hr />
        {this.state.noticia.desc}
      </div>
    )
  }
}



export default Noticia
