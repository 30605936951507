import React from 'react'
import { Link } from 'react-router-dom'
// import logo from '../logo.png'
import logo from '../logo/logo-footer.png'
import { MDBIcon } from 'mdbreact'
import axios from 'axios'

const linkStyle = {color: '#FFFFFF', cursor: 'pointer'}

class Footer extends React.Component {
  state = {
    phone: '',
    email: '',
    logos: []
  }

  componentDidMount () {
    axios.get(`${process.env.REACT_APP_ENDPOINT}globals`)
      .then(res => {
        this.setState({ 
          phone: res.data[0].acf.numero_de_contacto,
          email: res.data[0].acf.email_de_contacto
        })
      })
      .catch(err => { console.log(err) })
    axios.get(`${process.env.REACT_APP_ENDPOINT}footer`)
      .then(res => {
        let logos = res.data.map((logo, id) => {
          return <div key={id}><img src={logo.acf.imagen} width="200px" alt="" /></div>
        })
        this.setState({ logos })
      })
      .catch(err => {console.log(err)})
  }
  render() {
    return (
      <footer className="footer">
        <div className="footer-row">
          <div className='footer-logo' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <img src={logo} alt="" width="250px" />
            {/* <h1 style={{textAlign: 'center'}}><b>Corporación<br/>MasterChile</b></h1> */}
          </div>
          <div className="footer-item">
          <div style={{display: 'flex',flexDirection: 'column', flexWrap: 'wrap'}}>{this.state.logos}</div>
          </div>
          <div className="footer-item">
            <h2>Acerca de nosotros</h2>
            <p><Link style={linkStyle} to="/about">Quienes somos</Link></p>
            <p><Link style={linkStyle} to="/">Inicio</Link></p>
            {/* {devLinks} */}
            <h2>Contáctanos</h2>
            <p> <strong className="red-text"><MDBIcon icon="phone-alt" /> {this.state.phone}</strong></p>
            <p><strong className="white-text">{this.state.email}</strong></p>
          </div>
        </div>
        <p style={{textAlign: 'center', marginTop: 0, marginBottom: 0}}>{process.env.REACT_APP_NAME}® 2019</p>
      </footer>
    )
  }
}

export default Footer
