import React from 'react'
import history from '../router'
import Fade from 'react-reveal/Fade'
import axios from 'axios'
import noImage from '../logo/logo-noimage.png'
import { MDBIcon } from 'mdbreact'

const formattedDate = (date) => {
  return `${date.substr(8, 2)}-${date.substr(5, 2)}-${date.substr(0, 4)}`
}

class NoticiaSlider extends React.Component {
  state = {
    noticias: [],
    active: 0,
    show: false
  }
  componentDidMount () {
    
    axios.get(`${process.env.REACT_APP_ENDPOINT}noticias`)
      .then(res => {
        let noticias = res.data.map(n => {
          return {
            id: n.id,
            title: n.acf.titulo,
            desc: n.acf.desc,
            image: n.acf.portada ? n.acf.portada : noImage,
            fecha: formattedDate(n.date)
          }
        })
        this.setState({ noticias })
      })
      .catch(err => { console.log(err) })
      .finally(() => {
        this.changeActive()
        this.interval = setInterval(() => this.changeActive(), 10000)
      })
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  changeActive = (to=1) => {
    // do change animation
    this.setState({ show: false})
    setTimeout(this.changeNext(to), 500)
  }
  changeNext = (to) => {
    console.log('executed!')
    let lastActive = this.state.active
    let nextNew = lastActive + to
    if (nextNew >= this.state.noticias.length) nextNew = 0
    if (nextNew < 0) nextNew = this.state.noticias.length - 1
    this.setState({ show: true, active: nextNew })
  }

  arrowChange (to) {
    clearInterval(this.interval)
    this.interval = setInterval(() => this.changeActive(), 10000)
    this.setState({ show: false})
    setTimeout(()=> {this.changeNext(to)}, 500)
  }

  render () {
    let {id, title, image, desc, fecha} = {id: 0, title: 'Noticias', image: noImage, desc: 'Info', fecha: 'No Fecha'}
    if (this.state.noticias[this.state.active]) {
      title = this.state.noticias[this.state.active].title
      image = this.state.noticias[this.state.active].image
      desc = this.state.noticias[this.state.active].desc.replace(/<[^>]*>/g, '')
      fecha = this.state.noticias[this.state.active].fecha
      id = this.state.noticias[this.state.active].id
    }
    return (
      <div style={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center'}}>
        <div style={{color: '#888888', cursor: 'pointer'}} onClick={() => {this.arrowChange(-1)}}><MDBIcon icon="caret-left" size="3x" /></div>
        <Fade style={{minWidth: '90%'}} right opposite when={this.state.show}>
          <Noticia id={id} title={title} img={image} fecha={fecha} desc={desc} />
        </Fade>
        <div style={{color: '#888888', cursor: 'pointer'}} onClick={() => {this.arrowChange(1)}}><MDBIcon icon="caret-right" size="3x" /></div>
      </div>
    )
  }
}

class Noticia extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false
    }
  }

  

  render () {
    const {img, title, desc, fecha} = this.props

    return (
      // <Fade right opposite when={this.state.show}>
        <div className="noticia-box" onClick={() => history.push(`/news/${this.props.id}`)}>
          <div className="noticia-img"
            style={{
              backgroundImage: `url(${img})`
              }}></div>
          <div className="noticia-content">
            <div className="noticia-title"><h5><b>{title}</b></h5></div>
            <div className="noticia-desc">{desc}</div>
            <div className="noticia-fecha"><i>{fecha}</i></div>
          </div>
        </div>
      // </Fade>
    )
  }
}

export {Noticia, NoticiaSlider}