import React from 'react'
import { MDBCard, MDBCardTitle, MDBCardBody, MDBCardText } from 'mdbreact'
// import noImage from '../no-image.png'
import noImage from '../logo/logo-noimage.png'
import history from '../router'
import * as ROUTES from '../router/routes'

class CursoCard extends React.Component {

  handleClick = () => {
    let {id} = this.props
    window.scrollTo(0, 0)
    history.push(`${ROUTES.CURSO_DETALLE}/${id}`)
  }

  render() {
    const { img, title, desc } = this.props
    const outImg = img
      ? img
      : noImage
    return(
      <MDBCard onClick={this.handleClick} className="mt-4" style={{width: '18rem', cursor: 'pointer'}}>
        <div className="bounding-box" 
          style={{
            backgroundImage: `url(${outImg})`, 
            height: '13rem',
            width: 'auto'
            }}>
        </div>
        <MDBCardBody>
          <MDBCardTitle>{title}</MDBCardTitle>
          <MDBCardText>{desc}</MDBCardText>
        </MDBCardBody>
      </MDBCard>
    )
  }
}

export default CursoCard